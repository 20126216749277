import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Icons from "./Icons";
import { IconType } from "../contents/js/constants";
import { Link } from "react-router-dom";

const contents = (children) =>
  children && children.length > 1 ? (
    <>
      <Col lg="9">{children[0]}</Col>{" "}
      <Col className="postDetails">{children[1]}</Col>
    </>
  ) : (
    <Col lg="12">{children}</Col>
  );

/**
 * Box can accommodate two children.
 * @param {*} param0
 * @returns
 */
function Box({ children }) {
  return (
    <Container
      style={{ marginTop: "30px", minHeight: "120vh", padding: "20px" }}
    >
      <Row>
        <Link to="/">
          <Icons obj={{ iconType: IconType.HOME }}></Icons>{" "}
        </Link>
      </Row>
      <Row>{contents(children)}</Row>
    </Container>
  );
}

export default Box;
