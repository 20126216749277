import Box from "./Box";
import ContentList from "./ContentList.js";
import Footer from "./Footer.js";
import Header from "./Header";
import HeroImage from "./HeroImage";

function Home() {
  return (
    <>
      <Header />
      <HeroImage />
      <Box>
        <ContentList />
      </Box>
      <Footer />
    </>
  );
}

export default Home;
