/**
 * do not change this, it is required to load jsx to the page.
 * @param {} contentLink
 * @returns
 */
export async function getMaincontent(contentLink) {
  return (await contentPath.get(contentLink)()).default;
}
const contentPath = new Map();
contentPath.set(
  "londonDungeonCollapse",
  async () => await import("../blogcontents/londonDungeonCollapse")
);
contentPath.set(
  "chiaraMingarelli",
  async () => await import("../blogcontents/chiaraMingarelli")
);
contentPath.set(
  "bettyRobinson",
  async () => await import("../blogcontents/bettyRobinson")
);
contentPath.set(
  "cacheControl",
  async () => await import("../blogcontents/cacheControl")
);
contentPath.set("graphql", async () => await import("../blogcontents/graphql"));