import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getMaincontent } from "../contents/js/contentList";
import Box from "./Box";
import Footer from "./Footer";
import Header from "./Header";
import HeroImage from "./HeroImage";

const Content = () => {
  const { contentLink } = useParams();
  const [module, setModule] = useState("");

  useEffect(() => {
    async function loadContent() {
      setModule(await getMaincontent(contentLink));
    }
    loadContent();
  }, [contentLink]);

  return (
    <>
      <Header />
      <HeroImage />
      <Box>
        <Row>
          <span style={{ marginBottom: "4px" }} />
          {module}
        </Row>
      </Box>
      <Footer />
    </>
  );
};

export default Content;
