import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import Content from "./Content";

const RouteIndex = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/:contentLink" element={<Content />}></Route>
    </Routes>
  );
};

export default RouteIndex;
