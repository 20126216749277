import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  contentList,
  filterContentForCategory,
  filterContentForSubCategory,
} from "../contents/js/homePageContents";
import MobileShareIcon from "./MobileShareIcon";
import { useState } from "react";

const ContentList = () => {
  const [cList, setCList] = useState(contentList());

  const filterBySubCategory = (subCategory) =>
    setCList(filterContentForSubCategory(subCategory));

  const filterByCategory = (category) =>
    setCList(filterContentForCategory(category));

  return (
    <Row data-masonry='{"percentPosition": true }'>
      {cList.map((content, i) => {
        return (
          <Col key={i + `-col`} lg="4">
            {
              <Card
                key={i + `-card`}
                border="secondary"
                className="home-content box"
              >
                <Card.Header
                  onClick={() => filterByCategory(content.categoryTitle)}
                >
                  <a href="#">{content.categoryTitle}</a>
                </Card.Header>
                <Card.Body>
                  <Card.Subtitle
                    style={{ marginBottom: "4px" }}
                    onClick={() => filterBySubCategory(content.subCategory)}
                  >
                    <a href="#">{content.subCategory}</a>
                  </Card.Subtitle>
                  <Card.Title>
                    <Link
                      to={`/${content.contentLink}`}
                      state={{ contentLink: content.contentLink }}
                    >
                      {content.contentTitle}
                    </Link>
                  </Card.Title>
                  <Card.Text style={{ marginBottom: "4px" }}>
                    {content.contentDesc}
                  </Card.Text>
                  <MobileShareIcon contentLink={content.contentLink} />
                </Card.Body>
              </Card>
            }
          </Col>
        );
      })}
    </Row>
  );
};

export default ContentList;
