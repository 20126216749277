import { categories, contents } from "./contents";
import { sortedData } from "./generalUtils";

export const contentList = () =>
  sortedData(contents).map((cont) => {
    return {
      category: cont.catKey,
      contentTitle: cont.title,
      contentDesc: cont.desc,
      contentLink: cont.contentLink,
      when: cont.when,
      categoryTitle: categories.find((cat) => cat.key === cont.catKey)
        .categoryTitle,
      subCategory: categories
        .find((cat) => cat.key === cont.catKey)
        .subCategories.find((subCat) => subCat.key === cont.subCatKey).name,
    };
  });

export const filterContentForSubCategory = (subCategory) => {
  return sortedData(contents)
    .filter(
      (content) => content.subCatKey.toLowerCase() === subCategory.toLowerCase()
    )
    .map((cont) => {
      return {
        category: cont.catKey,
        contentTitle: cont.title,
        contentDesc: cont.desc,
        contentLink: cont.contentLink,
        when: cont.when,
        categoryTitle: categories.find((cat) => cat.key === cont.catKey)
          .categoryTitle,
        subCategory: categories
          .find((cat) => cat.key === cont.catKey)
          .subCategories.find((subCat) => subCat.key === cont.subCatKey).name,
      };
    });
};

export const filterContentForCategory = (category) =>
  sortedData(contents)
    .filter(
      (content) => content.catKey.toLowerCase() === category.toLowerCase()
    )
    .map((cont) => {
      return {
        category: cont.catKey,
        contentTitle: cont.title,
        contentDesc: cont.desc,
        contentLink: cont.contentLink,
        when: cont.when,
        categoryTitle: categories.find((cat) => cat.key === cont.catKey)
          .categoryTitle,
        subCategory: categories
          .find((cat) => cat.key === cont.catKey)
          .subCategories.find((subCat) => subCat.key === cont.subCatKey).name,
      };
    });
