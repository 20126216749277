
const HeroImage = () => {
  return (
    <section className="banner">
      <h1> Reflection on My Personal Quests</h1>
    </section>
  );
};

export default HeroImage;
