export const contents = [
  {
    id: 1,
    title: "A Ghastly Downpour: The London Dungeon Collapse of 1679.",
    desc: `A macabre story that sent shivers down the
    spines of Londoners and continues to fascinate us today`,
    contentLink: "londonDungeonCollapse",
    when: "2024-04-11",
    catKey: "history",
    subCatKey: "scary",
  },
  {
    id: 2,
    title: "The Inspiring Journey of Chiara Mingarelli.",
    desc: `Chiara Mingarelli, a journey that led her, as a teenager, 
    to play a pivotal role in one of the most groundbreaking scientific discoveries of our time`,
    contentLink: "chiaraMingarelli",
    when: "2024-04-12",
    catKey: "history",
    subCatKey: "inspirers",
  },
  {
    id: 3,
    title:
      "The Unbreakable Spirit of Betty Robinson: A Tale of Triumph and Inspiration",
    desc: `How Betty defied the odds and embarked on a grueling journey`,
    contentLink: "bettyRobinson",
    when: "2024-05-23",
    catKey: "history",
    subCatKey: "inspirers",
  },
  {
    id: 4,
    title: "GraphQL: A Powerful Alternative to RESTful Web Services",
    desc: `Let's dive into the world of GraphQL, a modern query language and runtime for APIs`,
    contentLink: "graphql",
    when: "2024-09-14",
    catKey: "technology",
    subCatKey: "graphql",
  },
  {
    id: 4,
    title: "Cache-Control: What it is and How to use it",
    desc: `Cache-control is an HTTP header used to specify caching policies for web
    resources`,
    contentLink: "cacheControl",
    when: "2024-09-16",
    catKey: "technology",
    subCatKey: "frontend",
  },
];

export const categories = [
  {
    key: "history",
    categoryTitle: "History",
    subCategories: [
      {
        name: "Scary",
        key: "scary",
      },
      {
        name: "Inspirers",
        key: "inspirers",
      },
    ],
  },
  {
    key: "technology",
    categoryTitle: "Technology",
    subCategories: [
      {
        name: "Graphql",
        key: "graphql",
      },
      {
        name: "FrontEnd",
        key: "frontend",
      },
    ],
  },
];
