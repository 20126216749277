import { IconType } from "../contents/js/constants";

const getIcon = (obj) => {
  switch (obj.iconType) {
    case IconType.TECHNOLOGY:
      return <i className="bi bi-laptop" style={{ marginRight: "10px" }}></i>;
    case IconType.SHARE:
      return <i onClick={obj.handleShareClick} className="bi bi-share" />;
    case IconType.WEB:
      return <i className="bi bi-globe"></i>;
    case IconType.HOME:
      return <i className="bi bi-house"></i>;
    case IconType.TWITTER:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-twitter-x"
          viewBox="0 0 16 16"
        >
          <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
        </svg>
      );
    default:
      return <></>;
  }
};

export default function Icons(props) {
  return getIcon(props.obj);
}
