import React from "react";
import Icons from "./Icons";
import { IconType } from "../contents/js/constants";

function MobileShareIcon(props) {
  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          url: props.contentLink
            ? `${window.location.href}/${props.contentLink}`
            : window.location.href,
        })
        .then(() => console.log("Shared successfully!"))
        .catch((error) => console.error("Sharing failed:", error));
    } else {
      // Fallback for browsers without native share support (optional)
      console.log("Native share not supported, use fallback");
    }
  };

  return (
    <button
      type="button"
      className="btn btn-link"
      style={{ textAlign: "left", padding: "0" }}
    >
      <Icons obj={{ iconType: IconType.SHARE, handleShareClick }}></Icons>
    </button>
  );
}

export default MobileShareIcon;
